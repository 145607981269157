<template>
  <div class="request--book-promo request--book-user" v-if="!currentPhone">
    <div class="left--part">
      <div class="title--text">
        {{ $t('general.phoneInput') }}
      </div>
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('localPhone') }">
        <client-only>
          <vue-tel-input
            id="phone"
            :value="localPhone ? localPhone : ''"
            @input="phoneInput"
            :validCharactersOnly="true"
            defaultCountry="sg"
            class="basic--input"
          />
        </client-only>
        <span class="val-error mt-2" v-if="validation.hasError('localPhone')">{{
          validation.firstError('localPhone')
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
export default {
  name: 'user-data-section',
  components: { VueTelInput },
  computed: {
    ...mapState({
      currentPhone: state => (state.global.user ? state.global.user.phone : null),
    }),
    localPhone: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.localPhone;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/SET_LOCAL_PHONE', val);
      },
    },
    localPhoneCountryCode: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.localPhoneCountryCode;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/SET_LOCAL_PHONE_COUNTRY_CODE', val);
      },
    },
  },
  validators: {
    localPhone: {
      validator(value) {
        value = value !== undefined ? value : null;
        return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
      },
    },
  },
  methods: {
    showModal(modalName) {
      this.$modal.show(modalName);
    },
    phoneInput(phone, phoneObject) {
      if (phoneObject && phoneObject.countryCode) {
        this.localPhoneCountryCode = phoneObject.countryCode;
      } else {
        this.localPhoneCountryCode = null;
      }
      this.localPhone = phone;
    },
  },
};
</script>

<style scoped></style>
